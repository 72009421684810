import * as React from "react";

const DividerWithIllustration = () => {
  return (
    <svg
      width="300"
      height="180"
      viewBox="310 0 300 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="illustration"
    >
      <path
        d="M581.741 98.8784L565.578 159.305C556.924 156.53 551.131 150.411 547.077 143.447L547.077 143.447C543.907 138.004 539.603 134.44 534.586 133.096C529.569 131.751 524.059 132.686 518.593 135.814L518.592 135.815C511.219 140.037 502.64 142.478 493.183 139.944C483.726 137.41 477.517 131.006 473.242 123.663L473.242 123.663C470.072 118.22 465.768 114.656 460.751 113.312C455.734 111.967 450.224 112.902 444.758 116.03L444.757 116.031C437.384 120.253 428.805 122.694 419.348 120.16C409.783 117.597 403.582 111.193 399.352 103.862C395.208 96.6807 393.016 88.7157 391.443 82.7967L407.705 22.0036C416.358 24.7785 422.152 30.8974 426.205 37.8612L426.206 37.8616C429.376 43.304 433.68 46.8684 438.697 48.2128C443.714 49.5571 449.223 48.6222 454.69 45.494L454.69 45.4938C462.064 41.2715 470.643 38.8303 480.1 41.3643C489.557 43.8983 495.766 50.302 500.04 57.6453L500.041 57.6457C503.211 63.088 507.515 66.6524 512.532 67.9968C517.549 69.3411 523.058 68.4062 528.525 65.278L528.525 65.2778C535.899 61.0556 544.478 58.6143 553.935 61.1483C563.519 63.7163 569.688 70.1778 573.882 77.572C578.016 84.8604 580.165 92.9371 581.741 98.8784Z"
        fill="#D604C1
        "
        stroke="#D604C1
        "
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M524.696 2.69255C521.105 -0.898166 515.284 -0.898166 511.693 2.69254C508.102 6.28326 508.102 12.1049 511.693 15.6957L515.997 19.9995L511.693 24.3034C508.102 27.8941 508.102 33.7158 511.693 37.3065C515.284 40.8972 521.105 40.8972 524.696 37.3065L529 33.0026L533.304 37.3065C536.895 40.8972 542.716 40.8972 546.307 37.3065C549.898 33.7158 549.898 27.8941 546.307 24.3034L542.003 19.9995L546.307 15.6957C549.898 12.105 549.898 6.2833 546.307 2.69259C542.716 -0.898126 536.895 -0.898131 533.304 2.69258L529 6.9964L524.696 2.69255Z"
        fill="#FD4D40"
        className="-redcross"
      />
      <path
        d="M397.202 68.8725L423.058 46.8587L416.907 80.2484L416.606 81.8826L418.262 82.0154L452.12 84.7295L424.153 103.997L422.784 104.94L423.862 106.205L445.887 132.058L412.486 125.91L410.851 125.609L410.719 127.266L408.016 161.12L388.737 133.155L387.793 131.787L386.529 132.865L360.688 154.887L366.824 121.488L367.125 119.854L365.469 119.722L331.625 117.016L359.579 97.7395L360.946 96.7966L359.869 95.5321L337.859 69.6877L371.246 75.8274L372.879 76.1278L373.012 74.4721L375.729 40.6251L394.994 68.5815L395.937 69.9495L397.202 68.8725Z"
        fill="#3DDC97"
        stroke="#3DDC97"
        strokeWidth="3"
        className="-greenstar"
      />
    </svg>
  );
};

export default DividerWithIllustration;
